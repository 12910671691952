<template>
  <div class="mv-detail"
       v-loading="loading">
    <div class="mv-wrap">
      <h4 class="head">MV详情</h4>
      <div class="video-wrap">
        <video :src="mvUrl"
               controls
               autoplay></video>
      </div>
      <div class="mv-info">
        <div class="mv-info-head">
          <img :src="avatarUrl"
               alt=""
               class="mv-avatar">
          <p class="mv-nickname">{{mvInfo.artistName}}</p>
        </div>
        <div class="mv-name">{{mvInfo.name}}</div>
        <div class="mv-other">
          <div class="mv-time">发布时间：{{mvInfo.publishTime}}</div>
          <div class="mv-playcount">播放次数：{{mvInfo.playCount | ellipsisPlayVolume}}</div>
        </div>
        <p class="mv-desc">{{mvInfo.desc}}</p>
      </div>

      <div class="mv-content-wrap"
           v-if="hotComments.length != 0">
        <h4 class="head">热门评论({{hotComments.length}})</h4>
        <ul>
          <li v-for="(item,index) in hotComments"
              :key="index">
            <img v-lazy="item.user.avatarUrl"
                 alt=""
                 class="comment-avatar">
            <div class="comment-info">
              <div class="comment">
                <span class="comment-user">{{item.user.nickname}}:</span>
                <span class="comment-content">{{item.content}}</span>
              </div>
              <div class="re-comment"
                   v-if="item.beReplied.length != 0">
                <span class="comment-user">@{{item.beReplied[0].user.nickname}}:</span>
                <span class="comment-content">{{item.beReplied[0].content}}</span>
              </div>
              <div class="comment-bottom">
                <p class="comment-time">{{item.time | LocaleString}}</p>
                <span class="comment-time iconfont icon-dianzan">{{item.likedCount}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="mv-content-wrap"
           v-loading="loadingComments">
        <h4 class="head">最新评论({{this.total}})</h4>
        <ul>
          <li v-for="(item,index) in comments"
              :key="index">
            <img v-lazy="item.user.avatarUrl"
                 alt=""
                 class="comment-avatar">
            <div class="comment-info">
              <div class="comment">
                <span class="comment-user">{{item.user.nickname}}:</span>
                <span class="comment-content">{{item.content}}</span>
              </div>
              <div class="re-comment"
                   v-if="item.beReplied.length != 0">
                <span class="comment-user">@{{item.beReplied[0].user.nickname}}:</span>
                <span class="comment-content">{{item.beReplied[0].content}}</span>
              </div>
              <div class="comment-bottom">
                <p class="comment-time">{{item.time | LocaleString}}</p>
                <span class="comment-time iconfont icon-dianzan">{{item.likedCount}}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="page-wrap">
          <el-pagination @current-change="handleCurrentChange"
                         background
                         :layout="pagi"
                         :total="total"
                         :page-size="pageSize"
                         :current-page="page">
          </el-pagination>
        </div>

      </div>
    </div>
    <div class="mv-recommend-wrap">
      <h4 class="head">相关推荐</h4>
      <ul class="mv-list">
        <li v-for="(item,index) in recommendMvs"
            :key="index">
          <div class="mv-img-wrap"
               @click="toMvDetail(item.id)">
            <img :src="item.cover"
                 alt="newMvs">
            <p class="iconfont icon-play play"></p>
            <p class="play-count iconfont icon-play">{{item.playCount | ellipsisPlayVolume}}</p>
            <p class="mv-duration">{{item.duration | formatDuration}}</p>
          </div>
          <div class="mv-info">
            <p class="title">{{item.name}}</p>
            <p class="author">{{item.artistName}}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  commentsAPI,
  playMVAPI,
  simiMVAPI,
  mvDetailAPI,
  artistsAPI
} from '@/api/api'
export default {
  data () {
    return {
      mvId: '',
      mvUrl: '',
      recommendMvs: [],
      mvInfo: {},
      avatarUrl: '',
      hotComments: [],
      comments: [],
      total: 0,
      pageSize: 10,
      page: 1,
      loading: true,
      loadingComments: false,
      pagi: 'prev, pager, next'
    }
  },
  watch: {
    $route (newVal) {
      this.searchMV(newVal.query.id)
    }
  },
  methods: {
    toMvDetail (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    },
    handleCurrentChange (page) {
      this.page = page
      this.getComments()
    },
    async getComments () {
      this.loadingComments = true
      const params = {
        id: this.$route.query.id,
        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize
      }
      const { data: res } = await commentsAPI(params, 'mv')
      if (Object.prototype.hasOwnProperty.call(res, 'hotComments')) {
        this.hotComments = res.hotComments
      }
      this.comments = res.comments
      this.total = this.mvInfo.commentCount - this.hotComments.length
      this.loadingComments = false
    },
    async searchMV (id) {
      this.loading = true
      // 获取mv播放url
      const { data: resplay } = await playMVAPI({ id })
      this.mvUrl = resplay.data.url

      // 获取相关mv推荐
      const { data: ressimi } = await simiMVAPI({ mvid: id })
      this.recommendMvs = ressimi.mvs

      // 获取MV信息
      const { data: resdetail } = await mvDetailAPI({ mvid: id })

      this.mvInfo = resdetail.data

      const { data: resart } = await artistsAPI({ id: this.mvInfo.artistId })
      this.avatarUrl = resart.artist.img1v1Url

      // 获取评论信息
      this.getComments()

      this.loading = false
    }
  },
  created () {
    const mvId = this.$route.query.id
    this.mvId = mvId

    this.searchMV(mvId)
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.pagi = 'prev,jumper,next'
    }
  }
}
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
}

.mv-detail {
  margin: 0 auto;
  padding: 20px;
  display: flex;
}

.mv-detail >>> .el-loading-spinner {
  top: 20%;
}

.mv-content-wrap >>> .el-loading-spinner {
  top: 50%;
}

.head {
  margin-bottom: 20px;
}

.mv-content-wrap .head {
  margin-top: 40px;
  font-size: 25px;
  font-weight: normal;
}

.mv-wrap {
  width: 800px;
  margin-right: 30px;
}

.mv-recommend-wrap {
  width: 15vw;
}

.video-wrap {
  width: 100%;
}

.video-wrap video {
  width: 100%;
  height: 100%;
  outline: none;
  object-fit: fill; /* 自动填充父元素 */
}

.mv-info-head {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.mv-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.mv-info-head p {
  font-size: 22px;
  margin-left: 15px;
}

.mv-name {
  font-size: 30px;
  color: #000;
  font-weight: bold;
}

.mv-other {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: grey;
  margin: 20px 0;
}
.mv-other div {
  font-size: 16px;
}
.mv-time {
  margin-right: 30px;
}

.mv-desc {
  width: 700px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.mv-list li {
  display: flex;
  margin-top: 30px;
}

.mv-list li:hover {
  background-color: rgb(240, 239, 239);
}

.mv-img-wrap {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.mv-img-wrap img {
  height: 120px;
  width: 210px;
  border-radius: 5px;
}

.mv-img-wrap:hover .play::before {
  opacity: 1;
}

.mv-img-wrap .play::before {
  content: '\e665';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
}

.play-count {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
}

.play-count::before {
  margin-right: 5px;
}
.mv-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mv-info p.title {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
}

.mv-info .author {
  margin-top: 20px;
  color: #a5a1a1;
  font-size: 16px;
}

.mv-content-wrap ul li {
  display: flex;
  margin: 10px 0 30px 0;
  width: 700px;
}

.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-info {
  flex: 1;
  font-size: 14px;
  margin-left: 10px;
}

.comment {
  margin-bottom: 10px;
}

.comment-user {
  color: #517eaf;
  margin-right: 10px;
  cursor: pointer;
}

.comment-content {
  line-height: 20px;
}

.comment-time {
  color: grey;
  margin-right: 20px;
  margin-top: 5px;
}

.comment-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
}

.re-comment {
  background-color: #f3f1f3;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
}

.mv-duration {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  font-size: 12px;
}

@media screen and (max-width: 479px) {
  .mv-detail {
    padding: 1vw;
  }

  .mv-recommend-wrap {
    display: none;
  }
  .mv-wrap {
    width: 84vw;
  }
  .mv-avatar {
    width: 14vw;
    height: 14vw;
  }
  .mv-info-head {
    margin: 3vw 2vw;
  }
  .mv-info-head p {
    font-size: 17px;
  }
  .mv-name {
    font-size: 18px;
  }
  .mv-other {
    margin: 3vw 0 1.5vw 0;
  }
  .mv-other div {
    font-size: 13px;
    margin: 0;
  }
  .mv-desc {
    width: 80vw;
    line-height: 14px;
    font-size: 13px;
    margin-bottom: 0;
  }
  .mv-content-wrap .head {
    margin-top: 6vw;
    font-size: 16px;
    font-weight: 600;
  }
  .mv-content-wrap ul li {
    width: 83vw;
  }
  .el-pagination >>> .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .el-pagination {
    margin: 2vw 0;
  }
}
</style>
